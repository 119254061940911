// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-competition-js": () => import("./../src/templates/competition.js" /* webpackChunkName: "component---src-templates-competition-js" */),
  "component---src-templates-compseason-js": () => import("./../src/templates/compseason.js" /* webpackChunkName: "component---src-templates-compseason-js" */),
  "component---src-templates-games-js": () => import("./../src/templates/games.js" /* webpackChunkName: "component---src-templates-games-js" */),
  "component---src-templates-season-js": () => import("./../src/templates/season.js" /* webpackChunkName: "component---src-templates-season-js" */),
  "component---src-templates-team-js": () => import("./../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

